import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { TransferenciasGenArchivoUSD } from '../../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos';

const paginationModel = { page: 0, pageSize: 10 };

interface Props {
    data: TransferenciasGenArchivoUSD[]
    setData: React.Dispatch<React.SetStateAction<TransferenciasGenArchivoUSD[]>>
    setSeleccionados: React.Dispatch<React.SetStateAction<string[]>>
    seleccionados: string[]
}

export const TableGenerarArchivoDolares = ({ data, setData, seleccionados, setSeleccionados }: Props) => {

    const editableFields = ['comitente', 'tipoDolar', 'cantidad', 'fecha'];

    const columns: GridColDef<TransferenciasGenArchivoUSD>[] = editableFields.map((field) => ({
        field,
        headerName: returnHeaderName(field),
        flex: 1,
        renderCell: (params) => (
            field !== "tipoDolar" ? <TextField
                variant="outlined"
                size="small"
                value={params.row[field as keyof TransferenciasGenArchivoUSD]}
                onChange={(event) => handleInputChange(event, params)}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }}
            /> : <Select
                value={params.row[field as keyof TransferenciasGenArchivoUSD]?.toString() || ""}
                onChange={(event) => handleInputChange(event as SelectChangeEvent<string>, params)}
                variant="outlined"
                size="small"
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }}
            >
                <MenuItem value="USD-LOCAL">LOCAL</MenuItem>
                <MenuItem value="USD-EXTERNO">EXTERIOR</MenuItem>
            </Select>
        )
    }));

    function returnHeaderName(field: string) {
        switch (field) {
            case "tipoDolar":
                return "TIPO DE DOLAR"
            default:
                return field.toUpperCase()
        }
    }


    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
        params: GridRenderCellParams<TransferenciasGenArchivoUSD>
    ) => {
        const { value } = event.target as { value: string }
        setData((prevRows) =>
            prevRows.map((row) =>
                row._id === params.id ? { ...row, [params.field]: value } : row
            )
        );
    };


    const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
        setSeleccionados(selectionModel.map((id: GridRowId) => id.toString()))
    };


    return (
        <div style={{ width: "100%", paddingLeft: 8, paddingRight: 8 }}>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{
                    border: 0,
                }}
                getRowId={(row) => row._id}
                density='compact'
                disableAutosize={true}
                disableColumnFilter={true}
                disableColumnResize={true}
                disableColumnMenu={true}
                disableRowSelectionOnClick={true}
                onRowSelectionModelChange={itm => handleRowSelection(itm)}
            />
        </div>
    );
};