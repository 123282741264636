import { TableColumn } from 'react-data-table-component';
import { RowOrdenesExteriorInteractive } from '../../interfaces/interfacesRows/rows';

export const columnsOrdenesExteriorInteractive = (   
    handleInputChange: (id: any, value: any) => void,
) : TableColumn<RowOrdenesExteriorInteractive>[] => [
    {   
        selector: (row: RowOrdenesExteriorInteractive) => row.Cuenta,
        name: 'Cliente',
        center: true,
        grow: 1.5,
        cell: (row) => row.Cuenta ? 
            ( 
                <span style={{ color: '#004B6F' }}>{row.Cuenta}</span>
            ) 
            :         
            (   
                <input
                    type="number"
                    placeholder="N° de cuenta"
                    key={row.id}
                    id={row.id.toString()}
                    onChange={(e) => handleInputChange(row.id, e.target.value)} 
                    style={{
                    textAlign: "center",
                    width: "90%",
                    padding: "5px",
                    fontSize: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    }}
                />
            ),
        style: {
            color:'#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.Estado,
        name: "Estado",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.Ejecutadas,
        name: "Ejecutadas",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.Especie,
        name: "Especie",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.Operación,
        name: "Operación",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.Cantidad,
        name: "Cantidad",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.Precio,
        name: "Precio",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.ImporteInicial,
        name: "Importe de orden",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    },
    {
        selector: (row: RowOrdenesExteriorInteractive) => row.ImporteFinal,
        name: "Importe de ejecución",
        center: true,
        grow: 1.5,
        style: {
            color: '#004B6F',
        },
    }
]