import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import { ButtonsContainer, Input, Label, LabelContainer, LogoAdjuntar, Select, SelectContainer, ErrorMessage } from "./elements";
import { procesarOrdenesExterior } from "../../../api/ordenesExterior";
import { useNavigate } from "react-router-dom";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 370,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    padding: '2em 1em',
};

const styleButtons = {
    width: "130px",
    height: "35px",
    fontWeight: "bold",
    borderRadius: '5px',
    color: 'white',
};

interface Props {
    open: boolean;
    setOpen: (param: boolean) => void;
}

export default function ModalSubirArchivo({ open, setOpen }: Props) {

    const navigate = useNavigate();
    const [archive, setArchive] = useState<File | null>(null);
    const [mercado, setMercado] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleCerrar = () => {
        setArchive(null);
        setMercado("");
        setOpen(false);
        setErrorMessage("")
    };

    const handleSubmit = async () => {
        if (mercado.length !== 0) {
            try {
                setErrorMessage("")
                const resp = await procesarOrdenesExterior(archive, mercado);

                resp && navigate("/consulta-ordenes-interactive", { state: { interactive: resp.data, mercado: mercado } });

                if (resp && resp.status === 200) {
                    handleCerrar();
                }
            } catch (error: any) {

                setErrorMessage(error.body);

            }
        }
    };

    return (
        <Modal open={open} onClose={handleCerrar}>
            <Box sx={style}>
                <Typography variant="h6" component="h2" style={{ fontFamily: "Montserrat", fontSize: 20, fontWeight: "bold", lineHeight: 1.2, width: 300, margin: "0em 0" }}>
                    Subir archivo
                </Typography>
                <SelectContainer>
                    <Select onChange={(e) => setMercado(e.target.value)} value={mercado}>
                        <option value="" disabled hidden>
                            Selecciona
                        </option>
                        <option value="SCHWAB">SCHWAB</option>
                        <option value="INTERACTIVE">Interactive brokers</option>
                    </Select>
                </SelectContainer>
                <LabelContainer>
                    <Label htmlFor="archivo">
                        {
                            archive ?
                                <LogoAdjuntar
                                    src={require(`../../../images/documentos/adjuntar-dorado.png`)}
                                    alt="logo"
                                />
                                :
                                <LogoAdjuntar
                                    src={require(`../../../images/documentos/adjuntar.png`)}
                                    alt="logo"
                                />
                        }
                        SUBIR ARCHIVO (CSV)
                    </Label>
                    <Input
                        type="file"
                        accept=".csv"
                        id="archivo"
                        onChange={(e) => setArchive(e.target.files?.[0] || null)}
                    />
                </LabelContainer>
                {errorMessage !== "" && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <ButtonsContainer>
                    <Button
                        style={{ ...styleButtons, backgroundColor: "#FF0000", fontSize: "0.8em" }}
                        onClick={handleSubmit}
                        variant="contained"
                        size="large"
                        disabled={!archive}
                    >
                        SIGUIENTE
                    </Button>
                </ButtonsContainer>
            </Box>
        </Modal>
    );
}
