import styled from "styled-components";
import { Link } from "react-router-dom";
import { ImgHTMLAttributes } from "react";

// Definimos la interfaz de las props
interface ContainerProps {
    height: number; // Altura en píxeles
    width: number;  // Ancho en píxeles
}

// Interfaz para las props
interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    alt: string;
    src: string;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #004b6f;
    position: absolute;
    top: 0;
    align-items: center;
    overflow-y: scroll;
    height: ${({ height }) => height * 0.99999}px;
    width: ${({ width }) => width * 0.15}px;
    /* Ocultar la barra de desplazamiento en WebKit (Chrome, Safari) */
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const ImageLogo = styled.img<ImageProps>`
    margin-top: 1.5em;
`;

export const HeroConteiner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
`;

export const TextContainer = styled.div`
    width: 85%;
    text-align: left;
    margin: 1.5em 0 0 1em;
`;

export const Sub = styled.h6`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 22px;
	color: #ecf5ff;
`;

export const Lista = styled.ul`
	list-style: none;
	/* font-family: "Open Sans"; */
	font-style: normal;
	font-weight: 700;
	font-size: 1em;
	line-height: 25px;
	color: rgba(236, 245, 255, 0.75);
	margin: 1em 0px;
	padding-left: 0;
`;

export const Item = styled.li`
    margin: 20px 0px;
`;

export const UserActionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 18px;
	width: "75%";
`;

export const TextIcon = styled.p`
    font-size: 16px;
	font-weight: bold;
	color: white;
    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
`;

export const LinkUrl = styled(Link)`
    text-decoration: none;
    color: rgba(236, 245, 255, 0.75);
    cursor: pointer;
`;




