import { Container } from "./elements";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { columnsOrdenesExteriorInteractive } from "../../helper/Colums/columnsOrdenesExterioresInteractive";
import { DataBox, Sub, SubContainer } from "../../components/Table/elements";
import { useLocation, useNavigate } from "react-router-dom";
import useTableInteractive, { generadorDeExcelInteractive } from "../../hooks/useTableInteractive";
import DataTable from "react-data-table-component";
import { Box, Button, CircularProgress } from "@mui/material";
import { guardarOrdenExterior } from "../../api/ordenesExterior";

export const Interactive = () => {

    const { width, height } = useWindowDimensions();
    let altura = `${height * 0.75}px`;
    let widthContainer = width * 0.84;
    let heightContainer = height * 0.85;

    const customStyles = {
        tableWrapper: {
            style: {
                display: "table",
                minHeight: height * 0.75,
                maxHeight: "33em",
                maxWidth: "100%",
                backgroundColor: "white",
                color: "#004B6F",
            },
        },
        header: {
            style: {
                minHeight: "56px",
            },
        },
        rows: {
            style: {
                minHeight: "58px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                backgroundColor: "rgba(142, 178, 221, 0.5)",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                whiteSpace: 'normal', // Permite que el texto se envuelva
            },
        },
    };

    const navigate = useNavigate()
    const location = useLocation()
    const dataInteractive = location.state?.interactive;
    const mercado = location.state?.mercado;
    const { data } = useTableInteractive({ dataInteractive });

    const handleInputChange = (id: number, value: number,) => {
        const inputValue = value.toString();
        const inputElement = document.getElementById(id.toString());

        if (inputValue.length > 8) {
            const stopValue = inputValue.slice(0, 8);

            if (inputElement && inputElement instanceof HTMLInputElement) {
                inputElement.value = stopValue
            }
            return
        }

        if (data !== undefined) {
            data[id].Cuenta = value;
        }
    }

    const handleSubmit = () => {
        navigate(location.pathname, { state: null, replace: true });
        navigate("/consulta-ordenes")
    }

    const handleDownload = async () => {
        //recorremos el data.cuenta
        try {
            await guardarOrdenExterior(data);

            generadorDeExcelInteractive(data)
            navigate(location.pathname, { state: null, replace: true });
            navigate("/consulta-ordenes")
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <Container style={{ width: width * 0.85, left: width * 0.15 }} >
                <div style={{ width: widthContainer, height: heightContainer, }}>
                    <DataBox>
                        <SubContainer>
                            <Sub>{mercado}</Sub>
                        </SubContainer>
                    </DataBox>
                    {
                        (data !== undefined && data.length > 0) ? (
                            <>
                                <DataTable
                                    columns={columnsOrdenesExteriorInteractive(handleInputChange)}
                                    data={data!}
                                    fixedHeader
                                    fixedHeaderScrollHeight={altura}
                                    customStyles={customStyles}
                                    noDataComponent={
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <CircularProgress style={{ color: "#004B6F" }} />
                                        </Box>
                                    }
                                    responsive
                                    keyField="id"
                                />
                                <Button
                                    style={{
                                        marginTop: "24px",
                                        marginRight: "0.7em",
                                        backgroundColor: "#004B6F",
                                        color: 'white',
                                        fontSize: "0.7em",
                                        padding: "0.7em 2em",
                                    }}
                                    onClick={handleDownload}
                                    variant="contained"
                                    size="medium"

                                >
                                    DESCARGAR ARCHIVO
                                </Button>
                                <Button
                                    style={{
                                        marginTop: "24px",
                                        backgroundColor: "#C9C9C9",
                                        marginLeft: "0.7em",
                                        color: "#000000",
                                        fontSize: "0.7em",
                                        padding: "0.7em 2em",
                                    }}
                                    onClick={handleSubmit}
                                    variant="contained"
                                    size="medium"
                                >
                                    CANCELAR
                                </Button>
                            </>
                        ) : (
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <>No se encontraron elementos</>
                                </Box>
                                <Button
                                    style={{
                                        marginTop: "24px",
                                        backgroundColor: "#C9C9C9",
                                        marginLeft: "0.7em",
                                        color: "#000000",
                                        fontSize: "0.7em",
                                        padding: "0.7em 2em",
                                    }}
                                    onClick={handleSubmit}
                                    variant="contained"
                                    size="medium"
                                >
                                    CANCELAR
                                </Button>
                            </>
                        )
                    }
                </div>
            </Container>
        </>
    )
}