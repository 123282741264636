import { Box, Button, FormControl, MenuItem, Modal, Select, SelectChangeEvent, styled } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useState } from "react";
import { ButtonsContainer, Container, HeaderContainer } from "./elements";


const styleBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    padding: '1em',
};

interface Props {
    open: boolean
    setOpen: (param: boolean) => void
    seleccionados: string[]
    onChangeEstado: (ids: string[], nuevoEstado: string) => void
    onSubmit: () => void
}

export const ModalCambiarEstado = ({open, setOpen, seleccionados, onChangeEstado, onSubmit}: Props) => {

    const { width } = useWindowDimensions()
    const [ newEstado, setNewEstado] = useState('');

    const handleCerrar = () => {
        setNewEstado("")
        setOpen(false)
    };

    const handleSubmit = () => {
        if (newEstado && seleccionados.length > 0) {
            onChangeEstado(seleccionados, newEstado)
            handleCerrar()
            onSubmit()
        } else {
            alert("Por favor selecciona un estado válido")
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        setNewEstado(event.target.value)
    }

    return (
        <Modal open={open} onClose={handleCerrar}>
            <Box sx={styleBox}>
                <Container style={{    width: width *0.25, }}>
                    <HeaderContainer>
                        <h3 style={{color: "black"}}>¿Querés cambiar de estado del pedido?</h3>
                        <Button 
                            onClick={handleCerrar}
                            sx={{ 
                                textAlign: "left",
                                justifyContent: "end",
                                margin: "0",
                                padding: "0",
                                color: "black",
                                fontSize: "18px",
                                fontWeight: "500",
                                cursor: "pointer",
                            }}
                            >X</Button>
                    </HeaderContainer>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                value={newEstado}
                                onChange={handleChange}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) { return "Selecciona estado" }
                                    return selected
                                    }
                                }
                                sx={{ width: "15em" }}
                            >
                                <MenuItem value={"Aprobada"}>Aprobada</MenuItem>
                                <MenuItem value={"Rechazada"}>Rechazada</MenuItem>
                                <MenuItem value={"Pendiente"}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <ButtonsContainer>
                        <Button 
                            onClick={handleSubmit}
                            variant="contained"
                            style={{backgroundColor: "#004b6f", width: "8em"}}
                        >Confirmar</Button>
                        <Button
                            onClick={handleCerrar}
                            variant="outlined"
                            sx={{
                                marginLeft: "0.5em",
                                width: "8em",
                                color: "black",
                                borderColor: "gray",
                            }}
                        >
                            Cancelar
                        </Button>
                    </ButtonsContainer>
                </Container>
            </Box>
        </Modal>
    )
}