import styled from "styled-components";

export const Container = styled.div`
    height: calc(100vh - 2em);
    position: absolute;
    top: 2em;
    display: flex;
    flex-direction: column;
    padding: 2em;
`;

export const Subtitulo = styled.p`
    font-size: 2em;
    font-weight: bold;
    color: rgba(0, 75, 111, 0.75);
    margin-left: 0.5em;
`;

export const RowContainerBetween = styled.div`
    height: 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ContainerOpcional = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: space-between;
`;
