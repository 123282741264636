import { Box, FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent } from '@mui/material'
import { Title, Text, ButtonsContainer, ButtonCancelar, ButtonConfirmarAccion, TextsContainer } from './elements'
import { useState } from 'react';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleConfirmarModal: () => void,
    tituloModal?: string,
    textoModal?: string,
    textoConfirmarModal: string,
    handleChange: (event: SelectChangeEvent<string>) => void
    tipoDolar: string
    formatName: string
}

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'center',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    minHeight: 130,
    boxShadow: 24,
    borderRadius: 2,
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
};

const ModalConfirmacion = ({
    open,
    handleClose,
    handleConfirmarModal,
    tituloModal,
    textoModal,
    textoConfirmarModal,
    handleChange,
    tipoDolar,
    formatName
}: Props) => {


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, }}>
                    <TextsContainer>
                        <Title>{tituloModal}</Title>
                        <Text>{textoModal}</Text>
                    </TextsContainer>

                    {/* Select para tipo de dólar */}
                    <FormControl fullWidth sx={{ mt: 2, pl: 4, pr: 4 }}>
                        {/* <InputLabel id="select-tipo-dolar-label">Tipo de Dólar</InputLabel> */}
                        <Select
                            labelId="select-tipo-dolar-label"
                            id="select-tipo-dolar"
                            value={tipoDolar}
                            onChange={handleChange}
                            disabled={!formatName.includes("7000")}
                        >
                            <MenuItem value="local">Dólar Local</MenuItem>
                            <MenuItem value="exterior">Dólar Exterior</MenuItem>
                        </Select>
                    </FormControl>


                    <ButtonsContainer>
                        <ButtonCancelar
                            onClick={handleClose}
                        >
                            Cancelar
                        </ButtonCancelar>
                        <ButtonConfirmarAccion
                            onClick={handleConfirmarModal}
                        >
                            {textoConfirmarModal}
                        </ButtonConfirmarAccion>
                    </ButtonsContainer>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalConfirmacion
