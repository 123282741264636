import { useEffect, useState } from "react";
import { getTransferenciasDolares } from "../api/pedidos_dolares";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { EstadoPedidoDolar } from "../components/Table/EstadoPedidoDolar/EstadoPedidoDolar";
import { DetallePedidosDolares } from "../components/Table/DetallePedidosDolares/DetallePedidosDolares";

interface Props {
    setLoading: (param: boolean) => void,
    filtroTenencia?: string | undefined,
    reloadTable?: boolean,
    setReloadTable?: React.Dispatch<React.SetStateAction<boolean>>,
    subtitulo?: string,
}

interface TransferenciaInterface {
    id: string,
    fecha: string,
    hora: string,
    ticker: string,
    cliente: string,
    cantidad: string | number,
    estado: string | ReactJSXElement,
    estadoActual: string,
    detalle: string | ReactJSXElement
    fechaLiq?: null | string;
    origen?: string;
    // idWebVieja?: string;
    // comentariosWebVieja?: string;
    // cbuTransferencia?: string;

}

export interface Pedido {
    id: string,
    origen?: string,
}

export default function usePedidosDolaresHook({ setLoading, filtroTenencia, reloadTable, setReloadTable, subtitulo }: Props) {

    const [pedidosDolares, setPedidosDolares] = useState<TransferenciaInterface[]>([]);
    const [pedidosDolaresFilt, setPedidosDolaresFilt] = useState<TransferenciaInterface[]>([]);
    const FECHA_LIMITE_TENENCIA = fechaResta(15)

    const [pedidos, setPedidos] = useState<Pedido[]>([]);

    const actualizarPedidos = (newValue: Pedido) => {
        setPedidos((prevs) => [...prevs, newValue]);
    };

    const deletePedido = (removeValue: Pedido) => {
        setPedidos((prevs) => prevs.filter((e) => e.id !== removeValue.id));
    };

    useEffect(() => {
        if (reloadTable && filtroTenencia && setReloadTable) {
            getPedidosDolares();
            setReloadTable(!reloadTable)
        }
    }, [reloadTable])


    useEffect(() => {
        if (filtroTenencia) {
            filtrarCuentaFecha(filtroTenencia)
        }


    }, [filtroTenencia, pedidosDolares])

    const [showAprobadasRechazadas, setShowAprobadasRechazadas] = useState<boolean>(false);

    // Petición a la api

    const getPedidosDolares = async () => {
        try {
            setLoading(true);
            const resTransferenciasDolares = await getTransferenciasDolares();
            // console.log(resTransferenciasDolares.data)

            let tranferenciasData = resTransferenciasDolares.data.body;
            tranferenciasData = tranferenciasData.reverse();

            const array = tranferenciasData.map((e) => {
                const id = e._id ? e._id : e.idWebVieja ? e.idWebVieja : "0"
                return {
                    id: id,
                    fecha: e.fecha,
                    hora: e.hora,
                    ticker: e.especie,
                    cliente: e.cuenta,
                    cantidad: e.cantidad,
                    origen: e.origen,
                    estado: <EstadoPedidoDolar id={id} estado={e.estado} origen={e.origen} actualizarPedidos={actualizarPedidos} deletePedido={deletePedido} subtitulo={subtitulo} />,
                    estadoActual: e.estado,
                    detalle: <DetallePedidosDolares id={id} cliente={e.cuenta} ticker={e.especie} cantidadPedido={e.cantidad} origen={e.origen}
                        idWebVieja={e.idWebVieja} comentariosWebVieja={e.comentariosWebVieja} cbuTransferencia={e.cbuTransferencia} />,
                    fechaLiq: e.fechaLiq,
                    tipoDolar: e.tipoDolar ? e.tipoDolar : "local"
                }
            });

            setPedidosDolares(array);

            filtroTenencia ? filtrarCuentaFecha(filtroTenencia) : setPedidosDolaresFilt(array);

            if (showAprobadasRechazadas) {
                setPedidosDolaresFilt(array);
            } else {
                const withOutAprobadasRechazadas = array.filter(e => {
                    return !(e.estadoActual === "APROBADA" || e.estadoActual === "RECHAZADA");
                });
                setPedidosDolaresFilt(withOutAprobadasRechazadas);
            }

            !(tranferenciasData?.length > 0) && setLoading(false);

            // return pendientesData;
        } catch (error) {
            console.log(error);
        }
    };

    // Funcion de filtro

    const filtPedidosTransferencias = (name: string) => {
        name = name.toLowerCase();

        let filtered = pedidosDolares.filter(
            (e) =>
                e.fecha.toLowerCase().includes(name) ||
                e.hora.toLowerCase().includes(name) ||
                e.ticker.toLowerCase().includes(name) ||
                e.cliente.toLowerCase().includes(name) ||
                e.estadoActual.toLowerCase().includes(name)
        );

        setPedidosDolaresFilt(filtered);
        filtered.length === 0 && setLoading(false);
    };

    const filtrarCuentaFecha = (name: string) => {
        // console.log(name)
        name = name.toLowerCase();

        let filtered = pedidosDolares.filter(
            (e) =>
                e.cliente.toLowerCase().includes(name) && convertirFecha(e.fecha) >= convertirFecha(FECHA_LIMITE_TENENCIA)
        );


        setPedidosDolaresFilt(filtered);
        filtered.length === 0 && setLoading(false);
    };

    const convertirFecha = (fecha: string): Date => {
        const [dia, mes, año] = fecha.split('/').map(Number);
        return new Date(año, mes - 1, dia); // Los meses empiezan desde 0 en JavaScript
    };

    function fechaResta(diasARestar: number) {
        // Crear una nueva fecha en la zona horaria de Argentina (UTC-3)
        const fecha = new Date(
            new Date().toLocaleString("en-US", {
                timeZone: "America/Argentina/Buenos_Aires",
            })
        );

        // Restar los días especificados
        fecha.setDate(fecha.getDate() - diasARestar);

        // Formatear el día, mes y año
        const dia = fecha.getDate().toString().padStart(2, "0");
        const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Suma 1 al mes, ya que los meses comienzan en 0 (enero) en JavaScript.
        const año = fecha.getFullYear();

        return `${dia}/${mes}/${año}`;
    }

    return {
        getPedidosDolares,
        filtPedidosTransferencias,
        pedidosDolaresFilt,
        showAprobadasRechazadas,
        setShowAprobadasRechazadas,
        pedidosDolares,
        setPedidosDolaresFilt,
        pedidos,
    };
}
