import { EditarOrdenExeriorResponse } from "../interfaces/interfaceOrdenesExterior/editarOrdenExteriorInterface.js";
import { GetOrdenesExteriorResponse } from "../interfaces/interfaceOrdenesExterior/getOrdenesExteriorInterface.js";
import { ProcesarOrdenesExteriorResponse } from "../interfaces/interfaceOrdenesExterior/procesarOrdenesExteriorInterface.js";
import { RowOrdenesExteriorInteractive } from "../interfaces/interfacesRows/rows.js";
import { guardarOrdenExeriorResponse } from "../interfaces/interfaceOrdenesExterior/guardarOrdenExteriorInterface.js";
import apiInstance from "../utils/Axios.js";

export async function getOrdenesExterior() {
	return await apiInstance.get<GetOrdenesExteriorResponse>("/ordenesExterior");
}

export async function editarOrdenExterior({id, estado, precio, importe, cantidad} : Datos) {
	return await apiInstance.post<EditarOrdenExeriorResponse>("/ordenesExterior/editar", {id, estado, precio, importe, cantidad});
}

export async function procesarOrdenesExterior(archive: any, mercado:string) {
    let data = new FormData();
    data.append("archivoBroker", archive);
    data.append("mercado", mercado);

    return await apiInstance.post<ProcesarOrdenesExteriorResponse>("/ordenesExterior/procesar", data);
}

export async function guardarOrdenExterior(data : RowOrdenesExteriorInteractive[]) {
	return await apiInstance.post<guardarOrdenExeriorResponse>("/ordenesExterior/guardar", data);
}

interface Datos {
    id: string,
    estado: string | undefined,
    precio: string | undefined, 
    importe: string | undefined, 
    cantidad: string | undefined,
}

