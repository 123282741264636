import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel } from "@mui/x-data-grid"
import { Transferencia } from "../../interfaces/interfaceTransferencias/getPedidosTranferencias"
import { Typography } from "@mui/material"
import React, { useContext, useState } from "react";
import { CheckInput, ContainerEstado } from "./elements";
import { roles } from "../../helper/roles";
import { UserContext } from "../../context/userContext/UserContexts";


const paginationModel = { page: 0, pageSize: 10 };

interface Props {
    transferencias: Transferencia[]
    setTransferencias: React.Dispatch<React.SetStateAction<Transferencia[]>>
    setSeleccionados: React.Dispatch<React.SetStateAction<string[]>>
    setCheckboxStates: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
    checkboxStates: { [key: string]: boolean }
}

export const PedidoTransferenciaTable = ({transferencias, setSeleccionados, checkboxStates, setCheckboxStates}: Props) => {

    const { user } = useContext(UserContext);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        params: GridRenderCellParams<Transferencia>
    ) => {
        const { checked } = event.target;
        setCheckboxStates((prevStates) => ({
            ...prevStates,
            [params.id]: checked,
        }));
    };
    // const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean }>({});

    const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
        setSeleccionados((prev) => {
            const newSelection = selectionModel.map((id: GridRowId) => id.toString());
            const isDuplicate = newSelection.some(id => prev.includes(id));
            
            if (isDuplicate) {
                const updatedSeleccionados = prev.filter(id => !newSelection.includes(id));
                return [...updatedSeleccionados];
            } else {
                return [...prev, ...newSelection];
            }
        });
    };
    
    const columnsToMap = [
        { "name": "fechaHora", "headerName": "Fecha/Hora",  "width": 200 },
        { "name": "nroCliente", "headerName": "Cliente",  "width": 110 },
        { "name": "cliente", "headerName": "Cliente descripcion",  "width": 230 },
        { "name": "moneda", "headerName": "Moneda",  "width": 100 },
        { "name": "importe", "headerName": "Importe",  "width": 90 },
        { "name": "banco", "headerName": "Banco",  "width": 200 },
        { "name": "tipoDeCuenta", "headerName": "Tipo de cuenta",  "width": 160 },
        { "name": "cuenta", "headerName": "Cuenta",  "width":  110 },
        { "name": "CBU", "headerName": "CBU",  "width":  250 },
        { "name": "tipoIdentImp", "headerName": "Tipo de ident. imp.",  "width":  100 },
        { "name": "numIdentImp", "headerName": "Num de ident. imp.",  "width":  100 },
        { "name": "titular", "headerName": "Titular",  "width":  230 },
        { "name": "estado", "headerName": "Estado", "width":  130 } 
    ]

    const columns: GridColDef<Transferencia>[] = columnsToMap.map((field: any) => ({
        field: field.name,
        width: field.width,
        headerName: field.headerName,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
            const getBackgroundColor = () => {
                switch (params.row.estado) {
                    case "Aprobada" : return "#99ff99";
                    case "Rechazada" : return "#ff9999";
                    case "Pendiente" : 
                    if(params.row.descripcion === "Sin saldo con aforo"){
                        return "#e69dfb"
                    } else {
                        return "#ffff99"
                    }
                        default: return "transparent";
                }
            };
            if (field.name !== "estado") {
                return (
                    <Typography
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            verticalAlign: "middle",
                            height: "100%",
                            padding: "0",
                            margin: "0",
                            backgroundColor: getBackgroundColor(),
                        }}
                    >
                        {params.row[field.name as keyof Transferencia]}
                    </Typography>
                );
            } else {
                return (
                    <ContainerEstado
                        onClick={(e) => {
                            if (params.row.estado === "Aprobada") {
                                e.stopPropagation()
                            }
                        }}
                        style={{ backgroundColor: getBackgroundColor() }}
                    >
                        {params.row.estado === "Aprobada" ? (
                            <span>{params.row.estado}</span>
                        ) : params.row.estado === "Pendiente" ? (
                            <>
                                <CheckInput
                                    type="checkbox"
                                    checked={checkboxStates[params.id] || false}
                                    onChange={(event) => handleInputChange(event, params)}
                                />
                                <span onClick={(e) => e.stopPropagation()}>{params.row.estado}</span>
                            </>
                        ) : params.row.estado === "Rechazada" ? (
                            user.rol === roles.administrador ? (
                                <>
                                    <CheckInput
                                        type="checkbox"
                                        checked={checkboxStates[params.id] || false}
                                        onChange={(event) => handleInputChange(event, params)}
                                    />
                                    <span onClick={(e) => e.stopPropagation()}>{params.row.estado}</span>
                                </>
                            ) : (
                                <span>{params.row.estado}</span>
                            )
                        ) : null}
                    </ContainerEstado>
                );
            }
        },
    }));

    return (
        <div>
            <DataGrid
                rows={transferencias}
                columns={columns}
                getRowId={(row) => row._id}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                sx={{
                display: "flex",
                "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    padding: "0",
                    margin: "0",
                },
                "& .super-app-theme--header": {
                    width: "100%",
                    display: "flex",
                    textAlign: "center",
                    padding: "0",
                    margin: "0",
                },
                "& .MuiDataGrid-cell": {
                    padding: "0px",
                },
                }}
                onRowSelectionModelChange={(itm) => handleRowSelection(itm)} 
            />
        </div>
    );
}