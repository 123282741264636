import { useState } from "react";
import { RowOrdenesExteriorInteractive } from "../interfaces/interfacesRows/rows";
import * as XLSX from 'xlsx';

export default function useTableInteractive({ dataInteractive }: any) {

    const [data] = useState<RowOrdenesExteriorInteractive[]>(() =>
        dataInteractive?.map((item: any, index: number) => ({
            _id: item._id,
            id: index,
            "Numero": item.Numero,
            Cuenta: item.Cuenta === "undefined" ? "" : item.Cuenta,
            Estado: "APROBADA",
            Operación: item.OperacionGrupo,
            Especie: item.Ticker,
            Cantidad: item.Cantidad,
            Ejecutadas: `${item.Ejecutadas}/${item.Cantidad}`,
            Precio: item.Precio,
            ImporteInicial: item.ImporteInicial === "undefined" ? "" : item.ImporteInicial,
            ImporteFinal: item.ImporteFinal === "undefined" ? "" : item.ImporteFinal,
            "Mercado": item.Mercado,
            "CUSIP": item.CUSIP,
            "Gasto de Broker": item["Gasto de Broker"],
            "Contraparte": item.Contraparte,
            "Moneda": item.Moneda,
            "Fecha Concertacion": item["Fecha Concertacion"],
            "Fecha Vencimiento": item["Fecha Vencimiento"],
            "Hora": item.Hora,
            "Custodia": item.Custodia,
            "Numero de Orden": item["Numero de Orden"],
            "Fecha Orden": item["Fecha Orden"],
            "Hora Orden": item["Hora Orden"],
        }))
    )

    return {
        data,
    };
}

export const generadorDeExcelInteractive = (data: any) => {
    // Modificar el parámetro Precio para que sea string y reemplace "." por ","
    data.forEach((a: any) => {
        if (a["Precio"] !== undefined && a["Precio"] !== null) {
            a["Precio"] = a["Precio"].toString().replace(".", ",");
        }
        if (a["Gasto de Broker"] !== undefined && a["Gasto de Broker"] !== null) {
            a["Gasto de Broker"] = a["Gasto de Broker"].toString().replace(".", ",");
        }
        if (a["Operación"] !== undefined) {
            a["OperacionGrupo"] = a["Operación"]; // Crear OperacionGrupo con el valor de Operación
            delete a["Operación"]; // Eliminar Operación
        }
        if (a["Especie"] !== undefined) {
            a["Ticker"] = a["Especie"]; // Crear OperacionGrupo con el valor de Operación
            delete a["Especie"]; // Eliminar Operación
        }
        delete a["_id"]; // Eliminar el parámetro _id
        delete a["id"]; // Eliminar el parámetro id
        delete a["ImporteInicial"]; // 
        delete a["ImporteFinal"]; // 
        delete a["Estado"]; // 
        delete a["Ejecutadas"]; // 
    });

    // Ordenar los campos según el orden especificado
    const orderedFields = [
        "Numero", "Mercado", "OperacionGrupo", "Ticker", "CUSIP", "Cantidad", "Precio",
        "Gasto de Broker", "Contraparte", "Hora", "Moneda", "Fecha Concertacion",
        "Fecha Vencimiento", "Custodia", "Cuenta", "Numero de Orden",
        "Fecha Orden", "Hora Orden"
    ];

    data.forEach((a: any) => delete a["id"])

    const orderedData = data.map((item: any) => {
        const orderedItem: any = {};
        orderedFields.forEach(field => {
            orderedItem[field] = item[field] !== undefined ? item[field] : null;
        });
        return orderedItem;
    });

    const dataSheet = XLSX.utils.json_to_sheet(orderedData);

    const dataBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(dataBook, dataSheet, 'Datos');

    const excelBinario = XLSX.write(dataBook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBinario], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'archivoCotizacion.xlsx';
    document.body.appendChild(link);
    link.click();

    link.remove();
    URL.revokeObjectURL(url);
}; 