import { GetTransferenciasArchivoResponse, TransferenciasGenArchivo, GetTransferenciasArchivoUSDResponse, TransferenciasGenArchivoUSD } from "../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos.js";
import apiInstance from "../utils/Axios.js";

export async function getTransferenciasArchivo() {
	return await apiInstance.get<GetTransferenciasArchivoResponse>("/generarArchivoCaja/transferencias");
}

export async function saveTransferenciasArchivo(dataOperaciones: TransferenciasGenArchivo[]) {
	return await apiInstance.post("/generarArchivoCaja/guardarTransferencias", { dataOperaciones });
}

export async function deleteTransferenciasArchivo(ids: string[]) {
	return await apiInstance.post("/generarArchivoCaja/borrarTransferencia", { ids });
}

export async function deleteAllTransferenciasArchivo() {
	return await apiInstance.delete("/generarArchivoCaja/borrarTransferencias");
}

export async function generarArchivoCaja(tipoTransferencias: string, dataOperaciones: TransferenciasGenArchivo[]) {
	return await apiInstance.post("/generarArchivoCaja", {
		tipoTransferencias,
		dataOperaciones
	});
}

//LAS RUTAS PARA EL MANEJO DE LOS ARCHIVOS EN DOLARES

export async function generarArchivoCajaUSD(dataOperaciones: TransferenciasGenArchivoUSD[]) {
	return await apiInstance.post("/generarArchivoCaja", { dataOperaciones, mercado: "DOLARES"});
}  

export async function deleteTransferenciasArchivoUSD(ids: string[]) {
	return await apiInstance.post("/generarArchivoCaja/borrarTransferencia", { ids, mercado: "DOLARES"});
}
export async function deleteAllTransferenciasArchivoUSD() {
	return await apiInstance.delete(`/generarArchivoCaja/borrarTransferencias?mercado=DOLARES`);
}

export async function saveTransferenciasArchivoUSD(dataOperaciones: TransferenciasGenArchivoUSD[]) {
	return await apiInstance.post("/generarArchivoCaja/guardarTransferencias", { dataOperaciones, mercado: "DOLARES"});
}

export async function getTransferenciasArchivoUSD () {
	return await apiInstance.get<GetTransferenciasArchivoUSDResponse>("/generarArchivoCaja/transferencias?mercado=DOLARES");
}