import axios from "axios";
import { initialState } from "../context/userContext/UserContexts";

export const baseURL = process.env.REACT_APP_API_HOST;
//export const baseURL = process.env.REACT_APP_API_HOST_LOCAL;


// const params = new URLSearchParams();

const apiInstance = axios.create({
	baseURL,
});
apiInstance.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	// config.headers.Authorization = token ? `Bearer ${token}` : "";
	config.headers.permit = token ? token : "";
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	config.headers.Authorization = token ? token : "";

	// params.append('permit', token);

	return config;
});

apiInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 419 || error.response.status === 401) {
			localStorage.setItem("token", "")
			localStorage.removeItem('myContext');
			window.location.href = "/";
		}
		return Promise.reject(error.response.data);
	}
);

export default apiInstance;
