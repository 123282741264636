import React from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ButtonOutline, Container, Option, RowContainer, RowContainerBetween, Select, styles, Subtitulo, TableContainer, WhiteBox } from './elements';
import { useGenerarArchivo } from '../../hooks/useGenerarArchivo';
import { Icon } from '@iconify/react';
import { TableGenerarArchivo } from '../../components/GenerarArchivoTable/GenerarArchivoTable';
import { Input } from '@mui/material';

export const GenerarArchivoPesos = () => {
	const { width } = useWindowDimensions();

	const { tipoTransferencias, handleChangeTipoTransferencia, transferenciasTable, setTransferenciasTable, addItem, saveApi, downloadArchive,
		deleteController, setSeleccionados, handleUpload } = useGenerarArchivo();


	const fileInputRef = React.useRef<HTMLInputElement | null>(null);

	const handleButtonClick = () => {
		fileInputRef.current?.click(); // Dispara el clic en el input
	};

	// const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const file = event.target.files ? event.target.files[0] : null;
	// 	setExcel(file)
	// 	// onFileSelect(file); // Llama a la función de callback con el archivo seleccionado
	// };



	return (
		<>
			<Container
				style={{
					width: width * 0.85,
					left: width * 0.15,
				}}
			>
				<RowContainerBetween>
					<Subtitulo>GENERADOR DE ARCHIVO EN PESOS</Subtitulo>
					<Select
						name="tiposTransferencias"
						value={tipoTransferencias}
						onChange={(e) => handleChangeTipoTransferencia(e)}
					>
						<Option value='LIQUIDACION'>
							LIQUIDACIÓN
						</Option>
						<Option value='GARANTIA'>
							GARANTÍA
						</Option>
					</Select>
				</RowContainerBetween>

				<WhiteBox>
					<RowContainer>
						<ButtonOutline onClick={addItem}> AGREGAR ÍTEM <Icon icon="basil:add-outline" style={styles.icon} /></ButtonOutline>
						{
							tipoTransferencias === 'GARANTIA' &&
							<div>
								<ButtonOutline onClick={handleButtonClick} style={{ textTransform: 'none' }}>
									CARGAR EXCEL <Icon icon="fe:export" style={{ marginLeft: 8 }} />
								</ButtonOutline>
								<input
									type="file"
									accept=".xlsx, .xls" // Acepta solo archivos de Excel
									ref={fileInputRef}
									style={{ display: 'none' }}
									onChange={handleUpload}
								/>
							</div>
						}
						<ButtonOutline onClick={async () => saveApi()}> GUARDAR <Icon icon="mingcute:save-line" style={styles.icon} /></ButtonOutline>
						<ButtonOutline onClick={async () => downloadArchive()}> DESCARGAR <Icon icon="ic:baseline-download" style={styles.icon} /></ButtonOutline>
						<ButtonOutline onClick={async () => deleteController()}> BORRAR SELECCIÓN <Icon icon="mdi:delete-outline" style={styles.icon} /></ButtonOutline>
					</RowContainer>
					<TableContainer>
						<TableGenerarArchivo data={transferenciasTable} setData={setTransferenciasTable} setSeleccionados={setSeleccionados} />
					</TableContainer>
				</WhiteBox>
			</Container>
		</>
	);
}
{/* <Input
type="file"
// accept={accept}
id={"excelLiquidaciones"}
// onChange={(e) => {
// 	if (e.target.files && e.target.files[0]) {
// 		setState(e.target.files[0]);
// 	}
// }}
/> */}