import React, { useEffect, useState } from 'react'
import { getPedidosTranferencias } from '../api/transferencias'
import { Transferencia } from '../interfaces/interfaceTransferencias/getPedidosTranferencias';
import { cambioEstadoTransferencias } from '../api/transferencias';

export const usePedidoTransferenciaHook = () => {

  const [allTransferencias, setAllTransferencias] = useState<Transferencia[]>([]);
  const [seleccionados, setSeleccionados] = useState<string[]>([])
  const [openModalCambiarEstado, setOpenModalCambiarEstado] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false)

  useEffect(() => {
    seteadorDeTransferencia()
  }, [])

  useEffect(() => {
    seteadorDeTransferencia()
    setSeleccionados([])
  }, [reload])

  const smaller = (str: string) => str.length <= 10 ? str : str.slice(0, 4);
  const refactorizado = (str: string) => {
    const fecha = str.slice(0, 10)

    const hora = str.slice(11, 19)
    return `${fecha}  ${hora}`
  }

  const seteadorDeTransferencia = async () => {
    try {
      const resp = await getPedidosTranferencias();
      const dataResolved = resp.data.body;
      
      const dataMapeada: Transferencia[] = dataResolved.map((item: any) => ({
        _id: item._id,
        fechaHora: refactorizado(item.createdAt),
        nroCliente: item.idComitente,
        cliente: item.titular || 'Falta',
        moneda: item.idMoneda  === "1" ? "Pesos" : "D. Billete",
        descripcion: item.descripcion,
        importe: item.importeString || '0',
        banco: item.bancoDescripcion || 'Falta',
        tipoDeCuenta: item.cuentaTipoDescripcion || 'Falta',
        cuenta: item.cuenta || 'Falta',
        CBU: item.cbu || 'Falta',
        tipoIdentImp: smaller(item.identificadorImpositivoTipo) || 'Falta',
        numIdentImp: item.identificadorImpositivo || 'Falta',
        titular: item.titular || 'Falta',
        estado: item.estado || 'Desconocido',
      }));
      setAllTransferencias(dataMapeada);
    } catch (error) {
      console.error('Error al obtener transferencias:', error);
    }
  };

  const handleOpenModalCambiarEstado = () => {
    setOpenModalCambiarEstado(true);
  };

  const actualizarEstado = async (ids: string[], nuevoEstado: string) => {
    const transferencias = [...ids]
    setReload(false)
    try{

    transferencias.forEach(async (tr) => await cambioEstadoTransferencias(tr, nuevoEstado))

    seteadorDeTransferencia();
    setReload(true)
    }catch(error){
      console.error(error)
    }
  }

  return {
    allTransferencias,
    setAllTransferencias,
    setSeleccionados,
    seleccionados,
    openModalCambiarEstado,
    setOpenModalCambiarEstado,
    handleOpenModalCambiarEstado,
    actualizarEstado,
  }
}
