import React, { useContext } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserContext } from "../../context/userContext/UserContexts";
import { Container, Item, Lista, Sub, TextContainer, LinkUrl, HeroConteiner, ImageLogo } from "./elements";
import { Accordion, AccordionDetails, AccordionSummary } from "../AccordionAsideBar/Accordion";
import { getMenu } from "../../helper/helperAside";
const logoImage = require("../../images/logo-rava-azul.png");

export const AsideAppbar = () => {

    const { user } = useContext(UserContext);
    const { width, height } = useWindowDimensions();
    const menu = getMenu(user.rol);

    const [expanded, setExpanded] = React.useState<string | boolean>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container
            height={height}
            width={width}
        >
            <HeroConteiner>
                <ImageLogo
                    src={logoImage}
                    alt="logo"
                />
                <TextContainer>
                    {menu && menu.map((a: any, index: number) =>
                        <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}a-content`}
                                id={`panel${index}a-header`}
                            >
                                <Sub>
                                    {a.subTitle.split('\n').map((line: any, i: any) => (
                                        <React.Fragment key={i}>
                                            {line}
                                            {i < a.subTitle.split('\n').length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                </Sub>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Lista>
                                    {a.items.map((a: any, index: number) =>
                                        <Item key={index}>
                                            <LinkUrl
                                                to={a.url}
                                            >
                                                {a.name}
                                            </LinkUrl>
                                        </Item>
                                    )}
                                </Lista>
                            </AccordionDetails>
                        </Accordion >
                    )}
                </TextContainer>
            </HeroConteiner>
        </Container >
    );
};