import React from "react"
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ButtonOutline, Container, RowContainer, RowContainerBetween, 
	styles, Subtitulo, TableContainer, WhiteBox } from './elements';
import { useGenerarArchivoDolares } from "../../hooks/useGenerarArchivoDolares";
import { Icon } from '@iconify/react';
import { TableGenerarArchivoDolares } from "../../components/GenerarArchivoTableDolares/GenerarArchivoTableDolares";

export const GenerarArchivoDolares = () => {
    const { width } = useWindowDimensions();

    const {
		transferenciasTableUSD, 
		setTransferenciasTableUSD, 
		addItem, 
		setSeleccionados, 
		deleteController,
		saveApi, 
		downloadArchive,
		handleUpload,
		seleccionados, 
	} = useGenerarArchivoDolares();


	const fileInputRef = React.useRef<HTMLInputElement | null>(null);

	const handleButtonClick = () => {
		fileInputRef.current?.click(); // Dispara el clic en el input
	};

	return (
		<>
			<Container
				style={{
					width: width * 0.85,
					left: width * 0.15,
				}}
			>
				<RowContainerBetween>
					<Subtitulo>GENERADOR DE ARCHIVO EN DOLARES</Subtitulo>
				</RowContainerBetween>

				<WhiteBox>
					<RowContainer>
						<ButtonOutline onClick={addItem}> 
							AGREGAR ÍTEM 
							<Icon icon="basil:add-outline" style={styles.icon} />
						</ButtonOutline>
							<div>
								<ButtonOutline onClick={handleButtonClick} style={{ textTransform: 'none' }}>
									CARGAR EXCEL <Icon icon="fe:export" style={{ marginLeft: 8 }} />
								</ButtonOutline>
								<input
									type="file"
									accept=".xlsx, .xls" // Acepta solo archivos de Excel
									ref={fileInputRef}
									style={{ display: 'none' }}
									onChange={handleUpload}
								/>
							</div>
						<ButtonOutline 
							disabled={seleccionados.length === 0}
							onClick={async () => saveApi()}
						>  
							GUARDAR SELECCIÓN
							<Icon icon="mingcute:save-line" style={styles.icon} />
						</ButtonOutline>
						<ButtonOutline 
							disabled={seleccionados.length === 0}
							onClick={async () => downloadArchive()}
						> 
							DESCARGAR SELECCIÓN
							<Icon icon="ic:baseline-download" style={styles.icon} />
						</ButtonOutline>
						<ButtonOutline 
							disabled={seleccionados.length === 0}
							onClick={async () => deleteController()}
						>
							BORRAR SELECCIÓN 
							<Icon icon="mdi:delete-outline" style={styles.icon} />
						</ButtonOutline>
					</RowContainer>
					<TableContainer>
						<TableGenerarArchivoDolares 
							data={transferenciasTableUSD} 
							setData={setTransferenciasTableUSD} 
							seleccionados={seleccionados}
							setSeleccionados={setSeleccionados} 
						/>
					</TableContainer>
				</WhiteBox>
			</Container>
		</>
	);
};

