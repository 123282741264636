import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { TransferenciasGenArchivo } from '../../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos';

const paginationModel = { page: 0, pageSize: 10 };

interface Props {
    data: TransferenciasGenArchivo[]
    setData: React.Dispatch<React.SetStateAction<TransferenciasGenArchivo[]>>
    setSeleccionados: React.Dispatch<React.SetStateAction<string[]>>

}

export const TableGenerarArchivo = ({ data, setData, setSeleccionados }: Props) => {

    const editableFields = ['origen', 'destino', 'tipoMovimiento', 'instrumento', 'cantidad', 'fecha'];

    const columns: GridColDef<TransferenciasGenArchivo>[] = editableFields.map((field) => ({
        field,
        headerName: returnHeaderName(field),
        flex: 1,
        renderCell: (params) => (
            field !== "tipoMovimiento" ? <TextField
                variant="outlined"
                size="small"
                value={params.row[field as keyof TransferenciasGenArchivo]}
                onChange={(event) => handleInputChange(event, params)}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }}
            /> : <Select
                value={params.row[field as keyof TransferenciasGenArchivo]?.toString() || ""}
                onChange={(event) => handleInputChange(event as SelectChangeEvent<string>, params)}
                variant="outlined"
                size="small"
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }}
            >
                <MenuItem value="DELIVER">DELIVER</MenuItem>
                <MenuItem value="RECEIVE">RECEIVE</MenuItem>
            </Select>
        )
    }));

    function returnHeaderName(field: string) {
        switch (field) {
            case "origen":
                return "CUENTA DE ORIGEN"
            case "destino":
                return "CUENTA DESTINO"
            case "tipoMovimiento":
                return "TIPO DE MOVIMIENTO"
            default:
                return field.toUpperCase()
        }
    }


    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
        params: GridRenderCellParams<TransferenciasGenArchivo>
    ) => {
        const { value } = event.target as { value: string }; // Fuerza el tipo de valor a `string`
        setData((prevRows) =>
            prevRows.map((row) =>
                row._id === params.id ? { ...row, [params.field]: value } : row
            )
        );
    };
    // const [selectionModel, setSelectionModel] = React.useState<number[]>([]);

    const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
        setSeleccionados(selectionModel.map((id: GridRowId) => id.toString())); // Convierte cada ID a string
    };


    return (
        <div style={{ width: "100%", paddingLeft: 8, paddingRight: 8 }}>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{
                    border: 0,
                    // height: 500, // Establece un alto fijo para el DataGrid (ajústalo según tus necesidades)
                    // overflow: 'auto', // Habilita el scroll
                }}
                getRowId={(row) => row._id}
                density='compact'
                disableAutosize={true}
                disableColumnFilter={true}
                disableColumnResize={true}
                disableColumnMenu={true}
                disableRowSelectionOnClick={true}
                // hideFooterPagination={true}
                onRowSelectionModelChange={itm => handleRowSelection(itm)}
                // disableVirtualization={false} // Asegura que la virtualización esté habilitada
                // rowBufferPx={500} // Reduce el número de filas en el buffer para optimizar

            // onRowSelectionModelChange={itm => setSeleccionados([...itm])}
            />
        </div>
    );
};