import styled from "styled-components";

export const ContainerEstado = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
`

export const CheckInput = styled.input`
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    cursor: pointer;
`