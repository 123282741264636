import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TextoTabla, TextoTablaWebVieja } from "./elements";
import { getRentaOperable } from "../../api/transferencias";
import { Body } from "../../interfaces/interfaceTransferencias/getRentaOperableInterface";
import CloseIcon from '@material-ui/icons/Close';
import { formatCurrency } from '../../helper/formatCurrency';
import { getTenenciaDolarRenta } from "../../api/tenencia";
import { TenenciaDolarRenta } from "../../interfaces/interfaceTenecia/getTenenciaInterface";

const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 1,
    paddingBottom: 3
};
interface Props {
    open: boolean,
    setOpen: (param: boolean) => void,
    id: string,
    cliente: string
    ticker: string
    cantidadPedido: string | number
    origen: string | undefined
    idWebVieja?: string;
    comentariosWebVieja?: string;
    cbuTransferencia?: string;
}

export default function ModalRenta({ open, setOpen, id, cliente, ticker, cantidadPedido, origen, idWebVieja, comentariosWebVieja, cbuTransferencia }: Props) {

    const [rentaOperable, setRentaOperable] = useState<Body[]>([])
    const [tenenciaDolarRenta, setTenenciaDolarRenta] = useState<TenenciaDolarRenta[]>([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)

    React.useEffect(() => {
        if (open) {
            if (origen === "Web vieja") {
                pedirDolarTenencia()
            } else {
                pedirRentaOperable()
            }
        }
        // if (open) pedirRentaOperable()
    }, [open])

    const pedirRentaOperable = async () => {
        setLoading(true)
        const { data } = await getRentaOperable(cliente)
        if (data.body) {
            let buscar = ticker === "DOLA 7000" ? "7000" : "10000"
            let rentaFiltrada = data.body.filter(r => r.especie.includes(buscar))
            setRentaOperable(rentaFiltrada)
            let suma = 0
            rentaFiltrada.forEach(element => {
                suma += parseFloat(element.cantidad)
            });
            setTotal(suma)
            setLoading(false)
        }
        setLoading(false)
    }

    const pedirDolarTenencia = async () => {
        setLoading(true)
        const resp = await getTenenciaDolarRenta(cliente)
        setTenenciaDolarRenta(resp.data.body.tenenciaDolarRenta)
        setLoading(false)
    }


    const handleCerrar = () => {
        setOpen(false);
    };


    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleCerrar}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <IconButton onClick={handleCerrar} style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} size="small">
                    <CloseIcon />
                </IconButton >
                <Typography
                    id="keep-mounted-modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: 24,
                        fontWeight: "bold",
                        lineHeight: 1.2,
                        marginBottom: 16
                    }}
                >
                    {origen !== "Web vieja" ? "Dividendos" : "Pedido de transferencia web vieja"}
                </Typography>

                {origen !== "Web vieja" ?

                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", width: "100%", backgroundColor: "#C6D8EE", padding: 8, borderRadius: 10, paddingRight: 24 }}>
                            <TextoTabla>Especie</TextoTabla>
                            <TextoTabla>Fecha de pago</TextoTabla>
                            <TextoTabla>Dias para operarlo</TextoTabla>
                            <TextoTabla>Cantidad</TextoTabla>
                        </div>

                        <div style={{
                            display: "flex", alignItems: "center", flexDirection: "column", width: "100%", padding: 8, paddingRight: 8, paddingTop: 12, maxHeight: "125px", overflowX: "hidden",
                            overflowY: "scroll", lineHeight: "16px"
                        }}>
                            {rentaOperable.map((r, index) => (
                                <div style={{ display: "flex", alignItems: "center", flexDirection: "row", width: "100%", paddingBottom: 8, }} key={index}>
                                    <TextoTabla>{`${ticker} - ${r.descripcion} `}</TextoTabla>
                                    <TextoTabla>{r.fechaLiq}</TextoTabla>
                                    <TextoTabla>{r.diasParaOperar}</TextoTabla>
                                    <TextoTabla>{"U$D " + formatCurrency(r.cantidad)}</TextoTabla>
                                </div>
                            ))}
                        </div>
                        {rentaOperable.length === 0 || loading ?
                            <Typography
                                id="keep-mounted-modal-title"
                                variant="h6"
                                component="h4"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    lineHeight: 1.2,
                                    marginBottom: 16
                                }}
                            >
                                {loading ? "Cargando datos" : "No hay dividendos pagados en los últimos 15 días"}
                            </Typography> : null
                        }

                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", width: "100%", backgroundColor: "#C6D8EE", padding: 8, paddingTop: 8, paddingRight: 24, borderRadius: 10 }}>
                            <TextoTabla></TextoTabla>
                            <TextoTabla></TextoTabla>
                            <TextoTabla></TextoTabla>
                            <TextoTabla>{"U$D " + total.toFixed(2)}</TextoTabla>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", width: "100%", backgroundColor: "#C6D8EE", padding: 8, borderRadius: 10, paddingRight: 24, marginTop: 4 }}>
                            <TextoTabla></TextoTabla>
                            <TextoTabla></TextoTabla>
                            <TextoTabla>TOTAL DEL PEDIDO DE CONVERSIÓN:</TextoTabla>
                            <TextoTabla>{"U$D " + cantidadPedido}</TextoTabla>
                        </div>
                    </div>

                    :
                    // mostrar cantidad dolar 7000 y 10000 en tenencia, comentarios en el pedido en la web vieja, cbu del cliente
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", paddingLeft: 16 }}>
                        <TextoTablaWebVieja>
                            <span dangerouslySetInnerHTML={{ __html: `<b>Comitente:</b> ${cliente}` }} />
                        </TextoTablaWebVieja>
                        <TextoTablaWebVieja>
                            <span dangerouslySetInnerHTML={{ __html: `<b>Cantidad del pedido:</b> ${cantidadPedido}` }} />
                        </TextoTablaWebVieja>
                        <TextoTablaWebVieja>
                            <span dangerouslySetInnerHTML={{ __html: `<b>Comentarios:</b> ${comentariosWebVieja}` }} />
                        </TextoTablaWebVieja>
                        <TextoTablaWebVieja>
                            <span dangerouslySetInnerHTML={{ __html: `<b>CBU transferencia:</b> ${cbuTransferencia}` }} />
                        </TextoTablaWebVieja>
                        <TextoTablaWebVieja style={{ marginTop: 4 }}>
                            <span dangerouslySetInnerHTML={{ __html: `<b>Tenencia dolar renta</b>` }} />
                        </TextoTablaWebVieja>
                        {tenenciaDolarRenta.map((e, index) => (
                            <TextoTablaWebVieja key={index}>
                                <span dangerouslySetInnerHTML={{ __html: `<b>${e.nombreEspecie}:</b> U$D ${e.cantidad}` }} />
                            </TextoTablaWebVieja>
                        ))}
                        <TextoTablaWebVieja style={{ marginTop: 8, fontSize: 12 }}>*Aclaración importante: este cliente hizo el pedido a través de la web vieja, por lo cual el proceso incluye la transferencia del monto al CBU requerido por el mismo. Este importe no va a quedar en la tenencia del cliente como dólar billete para reinvertirlo.</TextoTablaWebVieja>
                    </div>




                }
            </Box>
        </Modal >
    );
}