import { useEffect, useState } from "react";
import { generarArchivoCajaUSD, getTransferenciasArchivoUSD, saveTransferenciasArchivoUSD, 
	deleteAllTransferenciasArchivoUSD, deleteTransferenciasArchivoUSD } from "../api/transferencias_archivo";
import { TransferenciasGenArchivoUSD } from "../interfaces/interfacesTransferenciasArchivo/getTrransferenciasArchivos";
import { read, utils, WorkSheet } from "xlsx"; 

export const useGenerarArchivoDolares = () => {
    const [transferenciasStart, setTransferenciasStart] = useState<TransferenciasGenArchivoUSD[]>([])
    const [transferenciasTableUSD, setTransferenciasTableUSD] = useState<TransferenciasGenArchivoUSD[]>([])
	const [seleccionados, setSeleccionados] = useState<string[]>([])

	useEffect(() => {
		getAllTransferencias()
	}, [])
	

	const getAllTransferencias = async () => {
		try {
			const res = await getTransferenciasArchivoUSD();
			setTransferenciasTableUSD(res.data.body)
			setTransferenciasStart(res.data.body)
		} catch (error) {
			console.log(error)
		}
	}

    const addItem = () => {
		let arr = [
			{
				comitente: "", 
                tipoDolar: "USD-LOCAL", 
                cantidad: "", 
                fecha: getTodayDate(), 
				_id: generateUniqueId(), 
				__v: 0
			},
			...transferenciasTableUSD,]
            setTransferenciasTableUSD(arr)
	}

    function generateUniqueId(): string {
		let newId: string;

		do {
			newId = Array.from(crypto.getRandomValues(new Uint8Array(12)))
				.map((byte) => byte.toString(16).padStart(2, '0'))
				.join('');
		} while (transferenciasTableUSD.some((transferencia) => transferencia._id === newId));

		return newId;
	}

    function getTodayDate(): string {
		const today = new Date();
		const day = String(today.getDate()).padStart(2, '0');
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son de 0 a 11, así que sumamos 1
		const year = today.getFullYear();

		return `${day}/${month}/${year}`;
	}

	async function deleteController() {
		if (seleccionados.length === transferenciasTableUSD.length) {
			await deleteAllTransferenciasArchivoUSD()
			setTransferenciasTableUSD([])
		} else if (seleccionados.length > 0) {
			await deleteTransferenciasArchivoUSD(seleccionados)
			await getAllTransferencias()
		}
	}

//////////////////   FUNCIONES   PARA     GUARDAR    DATOS   SELECCIONADOS    //////////////////

	async function saveApi() {

		const datosSeleccionados = transferenciasTableUSD.filter(item => seleccionados.includes(item._id))
		await saveTransferenciasArchivoUSD([...transferenciasStart, ...datosSeleccionados])
		getAllTransferencias()
	}

//////////////////   FUNCIONES   PARA     DESCARGAR    EXCEL    //////////////////

	async function downloadArchive() {
		let datosProcesar = transferenciasTableUSD.filter(a => seleccionados.some(value => value === a._id))

		if (datosProcesar.length > 0) {
			datosProcesar = datosProcesar.map((dato) => ({
				...dato,
				comitente: `180/${dato.comitente}`,
				"ReceivingCashAccount": "180/1",
				"Currency": "USD",
				})
			);
		}

		const archiveResponse = await generarArchivoCajaUSD(datosProcesar)

		const blob = new Blob([archiveResponse.data], {
			type: 'application/SI2',
		});

		const blobUrl = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = blobUrl;
		link.download = 'TRANSFERENCIAS_USD.ict'; 

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(blobUrl);
	}

//////////////////  FUNCIONES   PARA    SUBIR    ARCHIVO   //////////////////

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const files = e.target.files;
	
		if (!files || files.length === 0) {
			return;
		}
	
		const file = files[0];
		if (!(file instanceof Blob)) {
			return;
		}
	
		const reader = new FileReader();
		reader.onload = (e) => {
			if (e.target) {
				const data = e.target.result
				const workbook = read(data, { type: "array" })
				const sheetName = workbook.SheetNames[0]
				const worksheet = workbook.Sheets[sheetName]
					//para los encabezados
					const headers: string[] = []
					const range = utils.decode_range(worksheet["!ref"] || "A1")
					for (let C = range.s.c; C <= range.e.c; ++C) {
						const cell = worksheet[ec(0, C)]
						if (cell && cell.v) headers.push(cell.v)
					}
					const headersMin = headers.map(e => e.toLowerCase());
				//datos con los encabezados agregados pasados a json
				const json: any[] = utils.sheet_to_json(worksheet, { header: headersMin, range: 1 })
				const dataMapeada = mapearExcel(json)
				setTransferenciasTableUSD((prevData) => [...prevData, ...dataMapeada])
			}
		};
		reader.readAsArrayBuffer(file)
	};

	function mapearExcel(arr: any[]): TransferenciasGenArchivoUSD[] {
		return arr.map(e => ({
			comitente: e["comitente"] ?? e["sourcecashaccount"],
			tipoDolar: e["tipo de dólar"] ?? e["paymentsystem"], 
			cantidad: e["cantidad"] ?? e["amount"],
			fecha: getTodayDate(),
			_id: generateUniqueId(),
			__v: 0,
		}));
	}
	
	const ec = (r: number, c: number): string => {
		return utils.encode_cell({ r, c });
	};

    return{
        transferenciasTableUSD,
		setTransferenciasTableUSD,
		addItem,
		setSeleccionados,
		saveApi,
		deleteController,
		downloadArchive,
		handleUpload,
		seleccionados, 
    }
}
