import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "../views/Login/Login";
import Subsanar from "../views/Subsanar/Subsanar";
import { Pendientes } from "../views/Pendientes/Pendientes";
import { Aprobados } from "../views/Aprobados/Aprobados";
import { Informados } from "../views/Informados/Informados";
import { Rechazados } from "../views/Rechazados/Rechazados";
import { EditarSubsanar } from "../views/EditarSubsanar/EditarSubsanar";
import { Documentos } from "../views/Documentos/Documentos";
import { Notificaciones } from "../views/Notificaciones/Notificaciones";
import { Subsanados } from "../views/Subsanados/Subsanados";
import { HistorialDeMovimientos } from "../views/HistorialDeMovimientos/HistorialDeMovimientos";
import { Historial } from "../views/Aplicacion/Historial/Historial";
import { Agregar } from "../views/Aplicacion/Agregar/Agregar";
import { Navbar } from "../components/Navbar/Navbar";
import { AgregarJuridica } from "../views/AgregarJuridica/AgregarJuridica";
import { OperarCarteras } from "../views/OperarCarteras/OperaraCarteras";
import { OrdenesCartera } from "../views/OrdenesCartera/OrdenesCartera";
import { Cotitulares } from "../views/Cotitulares/Cotitulares";
import { Tenencia } from "../views/Tenencia/Tenencia";
import { CambiarContraseña } from "../views/CambiarContraseña/CambiarContraseña";
import { TransferenciasPendientes } from "../views/Transferencias/Pendientes/TransferenciasPendientes";
import { TransferenciasAnuladas } from "../views/Transferencias/Anuladas/TransferenciasAnuladas";
import { TransferenciasAprobadas } from "../views/Transferencias/Aprobadas/TransferenciasAprobadas";
import { Criterios } from "../views/Transferencias/Criterios/Criterios";
import { AgregarCriterio } from "../views/Transferencias/AgregarCriterio/AgregarCriterio";
import { Dividendos } from "../components/Dividendos/Dividendos";
import { DividendosTable } from "../views/TablaDividendos/Dividendos-table";
import { EstadoRentasTable } from "../views/EstadoRentas/EstadoRentas";
import { Dolares } from "../components/Dolares/Dolares";
import { DolaresTable } from "../views/TablaDolares/Dolares-table";
import { AgregarAutorizado } from "../views/AgregarAutorizado/AgregarAutorizado";
import { OrdenesExterior } from "../views/OrdenesExterior/OrdenesExterior";
import { ConsultarPin } from "../views/ConsultarPin/ConsultarPin";
import { PedidosDolares } from "../views/PedidosDolares/PedidosDolares";
import { CBU } from "../components/CBU/CBU";
import { CBUsTable } from "../views/TablaCBUs/CBUsTable";
import { ReinversionRenta } from "../views/ReinversionRenta/ReinversionRenta";
import { Conciliaciones } from "../components/Conciliaciones/Conciliaciones";
import { ConciliacionesTable } from "../views/TablaConciliaciones/Conciliaciones-table";
import { ConciliacionesTitulosTable } from "../views/TablaTitulos/Titulos-table";
import { LiquidezBlanqueoTabla } from "../views/LiquidezBlanqueo/LiquidezBlanqueo";
import { GaraTable } from "../views/TablaGara/Gara-table";
import { GenerarArchivoPesos } from "../views/GenerarArchivoPesos/GenerarArchivoPesos";
import { GenerarArchivoDolares } from "../views/GenerarArchivoDolares/GenerarArchivoDolares";
import { GeneradorPrecios } from "../views/GeneradorPreciosIngematica/GeneradorPrecios";
import { GeneradorCotizacionesMoneda } from "../views/GeneradorCotizacionesMoneda/GeneradorCotizacionesMoneda";
import { Interactive } from "../views/Interactive/Interactive";
import { PedidoTransferencia } from "../views/PedidoTransferencia/PedidoTransferencia";

export const AppRouter = () => {
	return (
		<BrowserRouter>
			<Navbar />

			<Routes>
				<Route exact path="/" element={<Login />} />
				<Route path="/pendientes" element={<Pendientes />} />
				<Route path="/aprobados" element={<Aprobados />} />
				<Route path="/informados" element={<Informados />} />
				<Route path="/rechazados" element={<Rechazados />} />
				<Route path="/cotitulares/:id" element={<Cotitulares />} />
				<Route path="/subsanar/:id" element={<Subsanar />} />
				<Route path="/editar-subsanar" element={<EditarSubsanar />} />
				<Route path="/historial" element={<HistorialDeMovimientos />} />
				<Route path="/documentos/:id" element={<Documentos />} />
				<Route path="/notificaciones" element={<Notificaciones />} />
				<Route path="/subsanados" element={<Subsanados />} />
				<Route path="/agregar-juridica" element={<AgregarJuridica />} />
				<Route path="/app/historial" element={<Historial />} />
				<Route path="/app/agregar" element={<Agregar />} />
				<Route path="/operar-carteras" element={<OperarCarteras />} />
				<Route path="/ordenes-carteras" element={<OrdenesCartera />} />
				<Route path="/tenencia" element={<Tenencia />} />
				<Route
					path="/cambiar-contrasena"
					element={<CambiarContraseña />}
				/>
				<Route
					path="/transferencias-pendientes"
					element={<TransferenciasPendientes />}
				/>
				<Route
					path="/transferencias-anuladas"
					element={<TransferenciasAnuladas />}
				/>
				<Route
					path="/transferencias-aprobadas"
					element={<TransferenciasAprobadas />}
				/>
				<Route
					path="/liquidez-blanqueo"
					element={<LiquidezBlanqueoTabla />}
				/>
				<Route
					path="/transferencias-criterios"
					element={<Criterios />}
				/>
				<Route
					path="/transferencias/criterio"
					element={<AgregarCriterio />}
				/>
				<Route
					path="/procesar-dividendos"
					element={<DividendosTable />}
				/>
				<Route
					path="/procesar-conciliaciones"
					element={<ConciliacionesTable />}
				/>
				<Route
					path="/procesar-conciliacionesTitulos"
					element={<ConciliacionesTitulosTable />}
				/>
				<Route path="/estado-rentas" element={<EstadoRentasTable />} />
				<Route path="/procesar-dolares" element={<DolaresTable />} />
				<Route path="/pedidos-dolares" element={<PedidosDolares />} />
				<Route
					path="/procesar-gara"
					element={<GaraTable />}
				/>
				<Route
					path="/agregar-autorizado"
					element={<AgregarAutorizado />}
				/>
				<Route 
					path="/consulta-ordenes-interactive" 
					element={<Interactive/>}
				/>
				<Route 
					path="/consulta-ordenes" 
					element={<OrdenesExterior />} 
					/>
				<Route 
					path="/consulta-pin" 
					element={<ConsultarPin />} 
					/>
				<Route 
					path="/procesar-cbu" 
					element={<CBUsTable />} 
				/>
				<Route
					path="/reinversion-renta"
					element={<ReinversionRenta />}
				/>
				<Route
					path="/generador-archivo-pesos"
					element={<GenerarArchivoPesos />}
				/>
								<Route
					path="/generador-archivo-dolares"
					element={<GenerarArchivoDolares />}
				/>
				<Route
					path="/generador-precios"
					element={<GeneradorPrecios />}
				/>
				<Route
					path="/generador-cotizaciones"
					element={<GeneradorCotizacionesMoneda />}
				/>
				<Route 
					path="/pedido-transferencia" 
					element={<PedidoTransferencia />} 
				/>
			</Routes>
		</BrowserRouter>
	);
};
