import styled from "styled-components";

export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    margin-bottom: 0.5em;
    justify-content: space-between;
`

export const Container = styled.div`
    flex-direction: column;
    height: auto;
    padding-inline: 0.5em;
`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-block: 0.5em;
`